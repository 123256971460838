import React, { useState, useEffect } from "react";
import logo from "../assets/image/logo_eng.png";
import { BiLogIn } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Navigate } from "react-router-dom";
import { BiLogOut, BiUser } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { getcartcounts, logouts, getprofiles } from "../Redux/Action";
const Token = localStorage.getItem("AuthTok");

function Header() {
  const [show, setShow] = useState(true);
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();
  const [hov, setHov] = useState(false);
  const Count = useSelector(({ getcartcounts }) => getcartcounts.payload);
  useEffect(() => {
    dispatch(getcartcounts())
      .then((res) => {})
      .catch((err) => {
        if (err.response.data.error.message == "UnAuthorized") {
          dispatch(logouts());
          localStorage.removeItem("AuthTok");
          window.location.href = "/";
        }
      });
  }, []);
  const showList = () => {
    setShow(!show);
    setClose(!close);
  };
  const profilelist = useSelector(({ getprofiles }) => getprofiles.payload);
  useEffect(() => {
    if (Token) {
      dispatch(getprofiles()).then((res) => {});
    }
  }, []);
  console.log(profilelist);
  let name = profilelist?.userName;
  console.log(name);
  const styles = {
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "10px",
    fontSize: "22px",
    cursor: "pointer",
    "styles:hover": { textDecoration: "none" },
  };

  const [isActive, setIsActive] = useState(false);
  const [isAcActive, setIsAcActive] = useState(false);

  const activeLinkStyle = {
    display: "flex",
    // color: isActive ? "green" : "white",
    color: "white",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    background: "green",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "-10px",
    marginTop: "-5px",
    fontSize: "10px",
    // Set your desired color for the active link
    // Add any additional styles you need
  };

  const handleLinkClick = () => {
    setIsActive(!isActive);
  };

  const handleAcLinkClick = () => {
    setIsAcActive(!isActive);
  };

  return (
    <>
      <header class="top-header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-9">
              <div class="header-right">
                <ul>
                  <li>
                    <i class="bx bx-current-location"></i>
                    115/3, R.S. Road, Near Royal Theatre, Pallipalayam, Erode -
                    638 006
                  </li>

                  <li>
                    <i class="bx bxs-phone"></i>
                    <a href="tel:(+91) 7418724258">(+91) 7418724258</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3">
              <div class="header-left float-end">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/aadhimarudham"
                      target="_blank"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/aadhimarudhamhoney?igsh=Y3B0ZTNteG9jejY1"
                      target="_blank"
                    >
                      <i class="bx bxl-instagram "></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/aadhi-marudham-b62909281?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/917418724258" target="_blank">
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        class="modal fade fade-scale topheader"
        id="topheader"
        tabIndex="-1"
        role="dialog"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal">
              <i class="bx bx-x"></i>
            </button>
            <div class="modal-body">
              <div class="modal-social-link">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/aadhimarudham"
                      target="_blank"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/aadhimarudhamhoney?igsh=Y3B0ZTNteG9jejY1"
                      target="_blank"
                    >
                      <i class="bx bxl-instagram "></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/aadhi-marudham-b62909281?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/917418724258" target="_blank">
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="modal-list">
                <ul>
                  <li>
                    <i class="bx bx-location-plus"></i>
                    115/3, R.S Road, Near Royal Theatre, Pallipalyam, Erode-638
                    006, Namakkkal District.
                  </li>

                  <li>
                    <i class="bx bxs-phone"></i>
                    <a href="tel:(+0081)-6578-985-4849">(+91) 73974 25248</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-area">
        <div class="mobile-responsive-nav">
          <div class="container">
            <div
              class="mobile-responsive-menu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div class="logo">
                <a href="/">
                  <img src={logo} alt="logo" height={30} />
                </a>
              </div>
              <div>
                {" "}
                <span style={{ float: "right" }} onClick={() => setShow(!show)}>
                  {" "}
                  <span className="hamburger" style={{ cursor: "pointer" }}>
                    {show ? (
                      <GiHamburgerMenu style={{ fontSize: "30px" }} />
                    ) : (
                      <CgClose style={{ fontSize: "30px" }} />
                    )}
                  </span>
                </span>
              </div>
            </div>{" "}
            {show ? (
              false
            ) : (
              <div className="responsive">
                <ul className="p-0 mt-2 mb-3" style={{ listStyleType: "none" }}>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/" style={{ color: "black" }}>
                      {" "}
                      Home{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/shop" style={{ color: "black" }}>
                      Shop{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/categories" style={{ color: "black" }}>
                      {" "}
                      Categories
                    </NavLink>{" "}
                  </li>{" "}
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/blogs" style={{ color: "black" }}>
                      {" "}
                      Blog{" "}
                    </NavLink>
                  </li>
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <NavLink to="/About" style={{ color: "black" }}>
                      {" "}
                      About{" "}
                    </NavLink>
                  </li>
                  {Token ? (
                    <li
                      className="hover"
                      onClick={() => setShow(true)}
                      style={styles}
                    >
                      <NavLink to="/profile" style={{ color: "black" }}>
                        {" "}
                        Profile
                      </NavLink>{" "}
                    </li>
                  ) : (
                    false
                  )}
                  <li
                    className="hover"
                    onClick={() => setShow(true)}
                    style={styles}
                  >
                    <Link to="/Contact" style={{ color: "black" }}>
                      {" "}
                      Contact
                    </Link>{" "}
                  </li>{" "}
                  {Token ? (
                    // <li
                    //   className="hover"
                    //   onClick={() => setShow(true)}
                    //   style={styles}
                    // >
                    //   <a
                    //     href="/"
                    //     onClick={() => {
                    //       dispatch(logouts())
                    //         .then((r) => {})
                    //         .catch((e) => {
                    //           console.log(e);
                    //         });
                    //       localStorage.removeItem("AuthTok");
                    //     }}
                    //     style={{ color: "black" }}
                    //   >
                    //     {" "}
                    //     Logout
                    //   </a>{" "}
                    // </li>
                    false
                  ) : (
                    <li
                      className="hover"
                      onClick={() => setShow(true)}
                      style={styles}
                    >
                      <Link to="/login" style={{ color: "black" }}>
                        {" "}
                        Login
                      </Link>{" "}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div class="desktop-nav desktop-nav-three nav-area">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-sm navbar-light ">
              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <div class="navbar-brand-logo ">
                  <a class="navbar-brand" href="/">
                    <img src={logo} alt="Logo" height={60} />
                  </a>
                </div>{" "}
                <div className="m-auto">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <NavLink
                        to="/"
                        class="nav-link"
                        activeClassName="active-link"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/shop" class="nav-link">
                        Shop
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/categories" class="nav-link">
                        Categories
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/blogs" class="nav-link">
                        {" "}
                        Blog{" "}
                      </NavLink>
                    </li>
                    <li class="nav-item">
                      <NavLink to="/about" class="nav-link">
                        About
                      </NavLink>
                    </li>

                    <li class="nav-item">
                      <NavLink to="/contact" class="nav-link">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="others-options d-flex align-items-center">
                  <div class="optional-item"></div>
                  <div
                    class="navbar-nav"
                    style={{
                      width: "100%",
                      display: "flex",
                      // background:"red",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div class="optional-item">
                      <li class="nav-item">
                        <NavLink to="/cart" class="nav-link">
                          {/* <a class="optional-item-cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a> */}
                          {/* <div class="optional-item-cart"> */}
                          <div
                            onClick={() => handleLinkClick()}
                            style={{ display: "flex", width: "100%" }}
                          >
                            <AiOutlineShoppingCart
                              style={{
                                fontSize: "23px",

                                marginLeft: "-10px",
                              }}
                            />
                            {Count?.count > 0 ? (
                              <span style={activeLinkStyle}>{Count.count}</span>
                            ) : (
                              false
                            )}
                          </div>{" "}
                          {/* </div> */}
                        </NavLink>
                      </li>
                    </div>{" "}
                    {Token ? (
                      <div class="optional-item">
                        {" "}
                        <li class="nav-item">
                          <NavLink
                            className="optional-item-cart"
                            to="/profile"
                            style={{ display: "flex", marginTop: "-10px" }}
                          >
                            {" "}
                            <div
                              onClick={() => handleAcLinkClick()}
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                columnGap: "6px",
                              }}
                            >
                              <BiUser
                                style={{
                                  fontSize: "24px",
                                  marginBottom: "5px",
                                  textTransform: "capitalize",
                                }}
                              />
                              {name}
                            </div>
                            {/* <i
                              title="Account"
                              style={{ fontSize: "23px" }}
                              class="bx bx-user"
                            ></i>{" "}
                            <h6
                              style={{
                                fontSize: "18px",
                                marginTop: "5px",
                                marginLeft: "5px",
                              }}
                            >
                              Account
                            </h6> */}
                          </NavLink>
                        </li>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div class="optional-item" style={{ marginTop: "-5px" }}>
                    {Token
                      ? // <a
                        //   className="optional-item-cart"
                        //   href="/login"
                        //   style={{ fontSize: "18px" }}
                        //   onClick={() => {
                        //     dispatch(logouts())
                        //       .then((r) => {
                        //         //
                        //       })
                        //       .catch((e) => {
                        //         console.log(e);
                        //       });
                        //     localStorage.removeItem("AuthTok");
                        //   }}
                        // >
                        //   {" "}
                        //   <BiLogOut
                        //     style={{ fontSize: "20px", marginTop: "-5px" }}
                        //   />{" "}
                        //   Logout
                        // </a>
                        false
                      : !Token && (
                          <div
                            className="navbar-nav"
                            style={{
                              display: "flex",

                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "30px",
                              marginTop: "9px",
                            }}
                          >
                            <div className="nav-item">
                              <NavLink
                                className="nav-link"
                                to="/login"
                                style={{ fontSize: "18px", marginTop: "-10px" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    columnGap: "6px",
                                  }}
                                >
                                  <BiLogIn style={{ fontSize: "23px" }} />
                                  <span>Login</span>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        )}
                  </div>
                </div>
                <div class="mobile-nav">
                  <div class="mobile-other d-flex align-items-center">
                    <div class="optional-item">
                      <a class="optional-item-cart" href="/cart">
                        <i class="bx bx-cart-alt"></i>
                        {Count?.count > 0 ? (
                          <span>{Count.count}</span>
                        ) : (
                          false
                        )}{" "}
                      </a>
                    </div>{" "}
                    <div class="optional-item">
                      <a
                        class="topheader-btn"
                        href="#topheader"
                        data-bs-toggle="modal"
                        data-bs-target="#topheader"
                      >
                        <i class="bx bx-menu-alt-right"></i>
                      </a>
                    </div>
                    {Token ? (
                      <li className="hover" style={styles}>
                        <Link to="/Profile"> Profile</Link>{" "}
                      </li>
                    ) : (
                      <li className="hover" style={styles}>
                        <Link to="/login"> Login</Link>{" "}
                      </li>
                    )}
                    <div class="optional-item">
                      <BiLogIn />
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
