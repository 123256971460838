import React, { useState, useEffect } from "react";
import { getAddress, DeleteAddress } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { getcarts, checkouts, globalLoderAction } from "../Redux/Action";
import Accordion from "react-bootstrap/Accordion";

function CheckOut() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getAddress())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, []);

  const getaddr = useSelector(({ getAddress }) => getAddress.payload);
  const font = {
    fontWeight: "bold",
    textTransform: "Capitalize",
    textAlign: "center",
    fontSize: "15px",
    padding: "0px",
  };
  const weight = {
    textTransform: "Capitalize",
    color: "black",
    textAlign: "center",
    fontSize: "15px",
    padding: "0px",
  };
  const updat = {
    cursor: "pointer",
    textAlign: "center",
  };

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const [load, setLoad] = useState(true);
  const getCart = useSelector(({ getcarts }) => getcarts.payload);
  const [render, setRender] = useState(false);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  useEffect(() => {
    dispatch(getcarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  const [error, setError] = useState("");
  const [selectedAddressId, setSelectedAddress] = React.useState("");
  const handleChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  async function handlePlaceOrder() {
    if (!selectedAddressId) {
      setError("No Address Selected");
    } else {
      setError("");

      dispatch(checkouts({ addressId: selectedAddressId, currency: "INR" }))
        .then((res) => {
          //

          let url = "";
          if (res.data.data) url = res.data.data.url;

          if (url) {
            window.open(url, "_blank");
          }
        })
        .catch((res) => {
          //
        });
    }
  }

  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Checkout</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/cart">
                  Cart
                </a>
              </li>
              <li style={{ color: "#fff" }}>Checkout</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="checkout-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <h3>Billing Address</h3>
              <div className="container " style={{ padding: "0px" }}>
                <div className="cart-table table-responsive mt-3">
                  {Array.isArray(getaddr) && getaddr.length !== 0
                    ? getaddr?.map((G, i) => (
                        <div>
                          {/* <td style={weight}>{i + 1}</td> */}
                        <div style={{display:"flex"}}>  <span style={{ marginRight: "20px" }}>
                            <input
                              type="radio"
                              name="radio-buttons"
                              value={G.id}
                              onChange={handleChange}
                              style={{
                                background: "none",
                                border: "none",
                                boxShadow: "none",
                                color: "black",
                              }}
                            />
                          </span>{" "}
                          <h6
                            style={{
                              textTransform: "capitalize",
                              color: "green",
                            }}
                          >
                            {G.addressType} Address :
                          </h6></div>
                          <div style={{ display: "flex" }}>
                            {" "}
                            <p style={{ textTransform: "capitalize" }}>
                              {" "}
                              {G.street}, {G.city}, {G.district}, {G.landmark},{" "}
                              {G.state}-{G.zipcode}
                            </p>
                          </div>
                        </div>
                      ))
                    : false}
                </div>
                <div>
                  <a
                    href="/billingaddress"
                    class="default-btn border-radius-5 mt-2"
                  >
                    <BiPlus /> Add Address
                  </a>
                </div>
                {error ? (
                  <div className="conatiner pt-5">
                    <div class="alert alert-danger" role="alert">
                      <center> {error} </center>
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>

            <div class="col-lg-5">
              <div class="billing-sildbar pl-20">
                {Array.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
                  <div class="billing-totals">
                    <h3>Your Order Summary</h3>
                    <ul>
                      <div className="line">
                        <li class="border-line">
                          <b>Product Name</b>
                          <span>
                            <b>Total</b>
                          </span>
                        </li>
                        <li>
                          Actual Price <span> ₹ {getCart?.actualPrice}</span>
                        </li>
                        <li>
                          Discount{" "}
                          <span style={{ color: "green" }}>
                            {" "}
                            - ₹ {getCart?.discount}
                          </span>
                        </li>
                        <li>
                          Sub Total (Inclusive of all taxes)
                          <span> ₹ {getCart?.offerPrice}</span>
                        </li>
                        <li>
                          Package + Shipping Fee{" "}
                          <span> ₹ {getCart?.shippingFee}</span>
                        </li>
                      </div>
                      {/* <Accordion
                        defaultActiveKey="0"
                        style={{ border: "none",borderTop:"1px solid #f5f5f5" }}
                      >
                        <Accordion.Item eventKey="1">
                          <Accordion.Header style={{fontSize:"13px"}}>
                            View Amount Breakage
                          </Accordion.Header>
                          <Accordion.Body>
                            <li style={{ fontSize: "12px" }} className="py-0">
                              {" "}
                              GST (18%) <span> ₹ {(getCart?.gst).toFixed(1)}</span>
                            </li>
                            <li style={{ fontSize: "12px" }} className="py-0">
                              {" "}
                              CGST (9%)<span> ₹ {(getCart?.gst / 2).toFixed(1)}</span>
                            </li>
                            <li style={{ fontSize: "12px" }} className="py-0">
                              {" "}
                              SGST (9%)<span> ₹ {(getCart?.gst / 2).toFixed(1)}</span>
                            </li>
                            <li style={{ fontSize: "12px" }} className="pt-0">
                              {" "}
                              IGST (18%)<span> ₹ 0</span>
                            </li>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 15px",
                        }}
                      >
                        <div> Total Price</div>{" "}
                        <div> ₹ {getCart?.totalPrice}</div>
                      </div>
                    </ul>
                  </div>
                ) : (
                  false
                )}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <a
                    onClick={handlePlaceOrder}
                    class="default-btn border-radius-5"
                    style={{ cursor: "pointer" }}
                  >
                    Place Order
                  </a>
                </div>
                {/* <div class="payment-box">
                  <div class="payment-method">
                    <h3>Payment Method</h3>
                    <p>
                      <input
                        type="radio"
                        id="direct-bank-transfer"
                        name="radio-group"
                        checked
                      />
                      <label for="direct-bank-transfer">
                        Direct Bank Transfer
                      </label>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injehumour, or randomised words which don't
                      look even slightly believable.
                    </p>
                    <p>
                      <input type="radio" id="paypal" name="radio-group" />
                      <label for="paypal">PayPal</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="cash-on-delivery"
                        name="radio-group"
                      />
                      <label for="cash-on-delivery">Cash On Delivery</label>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CheckOut;
