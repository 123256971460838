import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getprofiles,
  updateprofiles,
  globalLoderAction,
} from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { BsPencil } from "react-icons/bs";
import Swal from "sweetalert2";
import { message } from "antd";
function UpdateProfile() {
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState({
    userName: "",
    alaisname: "",
    dob: "",
    gender: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(getprofiles())
      .then((res) => {
        console.log(res.data.data);
        setUser(res.data.data);
        setLoad(false);
      })
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  console.log(user);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateprofiles(user)).then((res) => {
      setLoad(false);
      message.success({
        content: res.data.data,
        style: {
          marginTop: "10vh",
        },
      });
      if (res.data.data === "Profile Updated")
        window.location.href = "/profile";
    });
  };

  return (
    <div>
      <div class="inner-banner inner-banner-bg8">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Update Profile</h3>
            <ul>
              <li>
                <a href="/" style={{ color: "#f9c113" }}>
                  My Account
                </a>
              </li>
              <li style={{ color: "#fff" }}>Update Profile</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="user-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="user-all-form">
                <div class="contact-form">
                  <h3 class="user-title">Update Profile</h3>{" "}
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                      <div class="form-group">
                        <label className="my-3" style={{ fontWeight: "bold" }}>
                          Username
                        </label>
                        <input
                          type="text"
                          name="username"
                          onChange={handleChange}
                          value={user.userName}
                          class="form-control"
                          placeholder="User Name"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <label className="mb-3" style={{ fontWeight: "bold" }}>
                          DOB
                        </label>
                        <input
                          type="Date"
                          name="dob"
                          value={user.dob}
                          onChange={handleChange}
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label className="mb-3" style={{ fontWeight: "bold" }}>
                          Gender
                        </label>
                        <select
                          class="form-select"
                          value={user.gender}
                          onChange={handleChange}
                          name="gender"
                          aria-label="Default select example"
                          style={{
                            height: "49px",
                          }}
                        >
                          <option selected> Choose Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Transgender</option>
                        </select>
                      </div>
                      <div class="col-lg-12 mt-5">
                        <div class="send-btn">
                          <center>
                            <button
                              type="submit"
                              class="default-btn border-radius-5"
                            >
                              Udpate Profile
                              <span></span>
                            </button>
                          </center>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { width: "100px", height: "100px", borderRadius: "50%" },
  // editimage:{
  //   color: "white",
  //   background: "black",
  //   width: "30px",
  //   padding: "7px",
  //   borderRadius: "50%",
  //   display: "flex",
  //   marginTop: "-70px",
  //   marginLeft: "230px",
  // }
};
