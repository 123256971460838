import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getcarts,
  removecarts,
  updatecarts,
  globalLoderAction,
} from "../Redux/Action";

function Cart() {
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState([]);

  const getCart = useSelector(({ getcarts }) => getcarts.payload);
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getcarts())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, []);
  useEffect(() => {
    if (getCart?.cart) {
      const quantities = getCart.cart.map((item) => item.units);
      setQuantity(quantities);
    }
  }, [getCart]);
  const handleClickplus = (i, C) => {
    dispatch(updatecarts({ units: "add", cartId: C.id }));
    const updatedQuantities = [...quantity];
    updatedQuantities[i] = updatedQuantities[i] + 1;
    setQuantity(updatedQuantities);
  };

  const handleClickminus = (i, C) => {
    dispatch(updatecarts({ units: "sub", cartId: C.id }));
    if (quantity[i] > 0) {
      const updatedQuantities = [...quantity];
      updatedQuantities[i] = updatedQuantities[i] - 1;
      setQuantity(updatedQuantities);
    }
  };

  const styles = {
    textAlign: "center",
  };
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>Cart</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>Cart</li>
            </ul>
          </div>
        </div>
      </div>

      <section class="cart-wraps-area pt-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <form>
                <div class="cart-wraps">
                  <div class="cart-table table-responsive">
                    {Array.isArray(getCart?.cart) &&
                    getCart?.cart?.length != 0 ? (
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>{" "}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Array.isArray(getCart?.cart) &&
                          getCart?.cart?.length != 0 ? (
                            getCart?.cart.map((C, i) => (
                              <tr>
                                <td class="product-thumbnail">
                                  <Link to={"/ProductDetails/" + C.productId}>
                                    <img
                                      src={
                                        process.env.REACT_APP_URL +
                                        C.variantImage
                                      }
                                      alt="item"
                                    />
                                  </Link>
                                </td>
                                <td class="product-name">
                                  <Link
                                    to={"/ProductDetails/" + C.productId}
                                    style={{
                                      textTransform: "capitalize",
                                      color: "black",
                                    }}
                                  >
                                    {C.productName}
                                  </Link>
                                </td>
                                <td class="product-price">
                                  <span class="unit-amount">
                                    ₹ {C.singleProductPrice}
                                  </span>
                                </td>
                                <td className="product-quantity">
                                  <div class="input-counter">
                                    <span
                                      class="minus-btn"
                                      onClick={() => handleClickminus(i, C)}
                                    >
                                      <i class="bx bx-minus"></i>
                                    </span>
                                    <input
                                      type="text"
                                      max="1"
                                      value={C.units}
                                      onChange={(e) =>
                                        setQuantity(e.target.value)
                                      }
                                    />
                                    <span
                                      class="plus-btn"
                                      onClick={() => handleClickplus(i, C)}
                                    >
                                      <i class="bx bx-plus"></i>
                                    </span>
                                  </div>
                                </td>
                                <td class="product-subtotal">
                                  <span class="subtotal-amount">
                                    ₹{C.totalPrice}
                                  </span>

                                 
                                </td>{" "}
                                <td class="product-subtotal">
                                  <a
                                    onClick={() => {
                                      setLoad(true);
                                      dispatch(
                                        removecarts({ cartId: C.id })
                                      ).then((res) => setLoad(false));
                                    }}
                                    class="remove"
                                  >
                                    <i
                                      style={{ fontSize: "23px" }}
                                      class="bx bx-trash"
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <center>
                              <h3 className="mt-5 mb-5 pb-5 pt-5">
                                "No Product Found!"
                              </h3>{" "}
                            </center>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <center>
                        {/* <img
                          src={cartimage}
                          className="mt-5 mb-5  pt-5"
                          style={{ width: "25%" }}
                        /> */}
                        <h3 className="mb-5 pb-3"> No Cart Product Found</h3>{" "}
                        <a href="/shop" className="default-btn border-radius-5">
                          Shop Now
                        </a>
                      </center>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div div style={{ display: "flex" }}>
        <div
          className=" col-4"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        ></div>
        <div
          className=" col-4"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {Array.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
            <Link
              to="/checkout"
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <button
                type="submit"
                className="default-btn border-radius-5 mt-5 mb-5"
              >
                Proceed to Checkout
              </button>
            </Link>
          ) : (
            false
          )}
        </div>{" "}
        <div
          className="mt-5 col-4"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        ></div>
      </div>
    </>
  );
}
export default Cart;
