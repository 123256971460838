import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getwishlists,
  addwishlists,
  globalLoderAction,
  getsingleproducts,
  addcarts,
} from "../Redux/Action";
import Swal from "sweetalert2";
import { message } from "antd";
// import wishlistimage from "../wishlist.svg";

function Whishlist() {
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  const wish = useSelector(({ getwishlists }) => getwishlists.payload);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getwishlists())])
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  const sproduct = useSelector(
    ({ getsingleproducts }) => getsingleproducts.payload
  );
  const [quantity, setQuantity] = useState(1);
  const [changeVariant, setChangeVariant] = useState(0);

  console.log("id", sproduct?.[changeVariant]);

  const SingleProduct = (proId) => {
    setLoad(true);
    dispatch(getsingleproducts({ productId: proId })).then((res) =>
      setLoad(false)
    );
  };

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const handleClick = (id) => {
    cartId.variantId = id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[change];
    dispatch(addcarts(cartId)).then((res) => {
      message.success({
        content: res?.data?.data,
        style: {
          marginTop: "10vh",
        },
      });
    });
  };
  const weight = {
    textTransform: "Capitalize",
    color: "black",
    textAlign: "center",
  };
  return (
    <>
      {" "}
      <section class="cart-wraps-area pt-100 pb-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <form>
                <div class="cart-wraps">
                  <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        {Array.isArray(wish?.data) &&
                        wish?.data?.length !== 0 ? (
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>

                            <th scope="col"> Add to Cart</th>
                            <th scope="col"> Action</th>
                          </tr>
                        ) : (
                          false
                        )}
                      </thead>

                      {Array.isArray(wish?.data) && wish?.data?.length !== 0 ? (
                        wish?.data?.map((w, i) => (
                          <tbody>
                            <tr>
                              <td class="product-thumbnail">
                                <Link to={"/ProductDetails/" + w.id}>
                                  <img
                                    src={
                                      process.env.REACT_APP_URL + w.productImage
                                    }
                                    alt="item"
                                    style={{ objectFit: "cover" }}
                                  />
                                </Link>
                              </td>

                              <td class="product-name">
                                <Link
                                  to={"/ProductDetails/" + w.id}
                                  style={{
                                    color: "black",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {w.productName}
                                </Link>
                              </td>

                              <td class="product-price">
                                <span className="unit-amount">
                                  {typeof w.discountPrice == "number" ? (
                                    <>₹{w.discountPrice}</>
                                  ) : (
                                    <span
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Product Currently Not Available
                                    </span>
                                  )}
                                </span>
                              </td>

                              <td class="product-subtotal">
                                <a
                                  onClick={() => handleClick(w.id)}
                                  class="default-btn border-radius-5"
                                >
                                  {" "}
                                  Add to Cart
                                </a>
                              </td>
                              <td class="product-subtotal">
                                {" "}
                                <a
                                  onClick={() => {
                                    setLoad(true);
                                    dispatch(addwishlists({ productId: w.id }))
                                      .then((res) => {
                                        message.success({
                                          content: res?.data?.data,
                                          style: {
                                            marginTop: "10vh",
                                          },
                                        });
                                        setRender(!render);
                                        setLoad(false);
                                        // Reload the page
                                        window.location.reload();
                                      })
                                      .catch((error) => {
                                        // Handle any errors
                                        message.error({
                                          content: error.message,
                                          style: {
                                            marginTop: "10vh",
                                          },
                                        });
                                        setLoad(false);
                                      });
                                  }}
                                  className="remove"
                                >
                                  <i className="bx bx-trash"></i>
                                </a>
                              </td>
                            </tr>{" "}
                          </tbody>
                        ))
                      ) : (
                        <center>
                          {/* <img
											   src={wishlistimage}
											   className="mt-5 mb-5"
											   style={{ width: "25%" }}
											 /> */}
                          <h3 className="mb-5 pb-3"> No Wishlist Products</h3>
                          <a
                            href="/shop"
                            className="default-btn border-radius-5"
                          >
                            Shop Now
                          </a>
                        </center>
                      )}
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Whishlist;
