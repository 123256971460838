import { combineReducers } from "redux";
import {
  User,
  resetpasswords,
  verifypasswords,
  getcategories,
  getbanner,
  getproducts,
  getcategoryproducts,
  getsingleproducts,
  addwishlists,
  getwishlists,
  addcarts,
  getcarts,
  getcartcounts,
  updatecarts,
  removecarts,
  buynows,
  checkouts,
  searchs,
  hotdeals,
  foryous,
  getorders,
  getordersdetails,
  faqs,
  registers,
  logouts,
  getprofiles,
  updateprofiles,
  addAddress,
  getAddress,
  getreviews,
  addreviews,
  productspecifications,
  myreviews,
  deletereviews,
  DeleteAddress,
  filters,
  getWelcomeMessages,
  globalLoder,
} from "./Reducer";

export default combineReducers({
  User,
  resetpasswords,
  verifypasswords,
  getcategories,
  getbanner,
  getproducts,
  getcategoryproducts,
  getsingleproducts,
  addwishlists,
  getwishlists,
  addcarts,
  filters,
  getcarts,
  getcartcounts,
  updatecarts,
  removecarts,
  buynows,
  checkouts,
  searchs,
  hotdeals,
  foryous,
  getorders,
  getordersdetails,
  faqs,
  registers,
  logouts,
  getprofiles,
  updateprofiles,
  addAddress,
  getAddress,
  getreviews,
  addreviews,
  productspecifications,
  myreviews,
  deletereviews,
  DeleteAddress,
  getWelcomeMessages,
  globalLoder,
});
