export const authenticate = {
  login: {
    success: "authenticateloginsuccess",
    error: "authenticateloginerror",
  },
};

//paynow
export const payNow = {
  pay: {
    success: "payNowSuccess",
    error: "payNowError",
  },
};

// Welcome Message
export const welcomeMessage = {
  welcome: {
    success: "welcomeMessageSuccess",
    error: "welcomeMessageSuccessError",
  },
};

//Reset Password
export const resetpassword = {
  reset: {
    success: "resetpasswordresetsuccess",
    error: "resetpasswordreseterror",
  },
};
//Verify Password
export const verifypassword = {
  verify: {
    success: "verifypasswordresetsuccess",
    error: "verifypasswordreseterror",
  },
};
//category
export const categories = {
  getcategories: {
    success: "getcategoriessuccess",
    error: "getcategorieserror",
  },
};
//banner
export const banner = {
  getbanner: {
    success: "getbannersuccess",
    error: "getbannererror",
  },
};
//products
export const products = {
  getproducts: {
    success: "getproductssuccess",
    error: "getproductserror",
  },
};

export const categoryproducts = {
  getproducts: {
    success: "getcategoryproductssuccess",
    error: "getcategoryproductserror",
  },
};

export const singleproducts = {
  getproducts: {
    success: "getsingleproductssuccess",
    error: "getsingleproductserror",
  },
};
//HotDeals
export const hotdeal = {
  hot: {
    success: "gethotsuccess",
    error: "gethoterror",
  },
};
//Foryou
export const foryou = {
  you: {
    success: "getyousuccess",
    error: "getyouerror",
  },
};
//wishlist
export const getwishlist = {
  get: {
    success: "getwishlistgetsuccess",
    error: "getwishlistgeterror",
  },
};

export const addwishlist = {
  add: {
    success: "addwishlistsuccess",
    error: "addwishlisterror",
  },
};
// cart
export const addcart = {
  add: {
    success: "addcartsuccess",
    error: "addcarterror",
  },
};

export const getcart = {
  get: {
    success: "getcartsuccess",
    error: "getcarterror",
  },
};

export const getcartcount = {
  get: {
    success: "getcartcountsuccess",
    error: "getcartcounterror",
  },
};
export const updatecart = {
  update: {
    success: "updatecartsuccess",
    error: "updatecarterror",
  },
};
export const removecart = {
  remove: {
    success: "removecartsuccess",
    error: "removecarterror",
  },
};

//Buy Now
export const buynow = {
  buy: {
    success: "buysuccess",
    error: "buyerror",
  },
};
//Checkout
export const checkout = {
  check: {
    success: "checkoutsuccess",
    error: "checkouterror",
  },
};
//Search
export const searchproduct = {
  search: {
    success: "checkoutsuccess",
    error: "checkouterror",
  },
};
//filter
export const filterproduct = {
  filter: {
    success: "filtersuccess",
    error: "filtererror",
  },
};
//orders
export const getorder = {
  get: {
    success: "getorderssucccess",
    error: "getordersfailure",
  },
};
//Get Order Details
export const getorderdetail = {
  details: {
    success: "getorderdetailssuccess",
    error: "getorderdetailsfailure",
  },
};
//Faq
export const faq = {
  quest: {
    success: "faqdetailssuccess",
    error: "faqdetailserror",
  },
};
// Register
export const register = {
  sent: {
    success: "registersuccess",
    error: "registererror",
  },
};
// Logout
export const logout = {
  sent: {
    success: "logoutsuccess",
    error: "logouterror",
  },
};
//Profile
export const getprofile = {
  get: {
    success: "getprofilesuccess",
    error: "getprofileerror",
  },
};
//UpdateProfile
export const updateprofile = {
  update: {
    success: "updateprofilesuccess",
    error: "updateprofileerror",
  },
};
//Address
export const addAddres = {
  add: {
    success: "addaddresssuccess",
    error: "addaddresserror",
  },
};
//DeleteAddress
export const deleteAddres = {
  delete: {
    success: "deleteaddresssuccess",
    error: "deleteaddresserror",
  },
};
//getAddress
export const getAddres = {
  get: {
    success: "getgetssuccess",
    error: "getgeterror",
  },
};
//getReviews
export const getReview = {
  get: {
    success: "getreviewsssuccess",
    error: "getreviewserror",
  },
};
//Add reviews
export const addReview = {
  add: {
    success: "addreviewsssuccess",
    error: "addreviewserror",
  },
};
//Add reviews
export const delReview = {
  delete: {
    success: "deletereviewsssuccess",
    error: "deletereviewserror",
  },
};
//Product Spec
export const productspec = {
  spec: {
    success: "productspecsuccess",
    error: "productspecerror",
  },
};
//Myreviews
export const myreview = {
  review: {
    success: "myreviewssuccess",
    error: "myreviewserror",
  },
};
//Update Reviews
export const updatereview = {
  review: {
    success: "updatereviewssuccess",
    error: "updatereviewserror",
  },
};
export const deletereview = {
  review: {
    success: "deletereviewssuccess",
    error: "deletereviewserror",
  },
};
//filter
//Filter Products
export const fProducts = {
  fProduct: {
    success: "fProductsSuccess",
    error: "fProductsError",
  },
};
