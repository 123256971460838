import React, { useState, useEffect } from "react";
import "./Order.css";
import { getorders, globalLoderAction } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoEye } from "react-icons/io5";

function Order() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    Promise.allSettled([dispatch(getorders())])
      .then((res) => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const order = useSelector((state) => state.getorders.payload);
  console.log(order);

  const styles = {
    textAlign: "center",
    color: "black",
  };

  return (
    <div>
      <section className="cart-wraps-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form>
                <div className="cart-wraps">
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        {Array.isArray(order) && order.length !== 0 && (
                          <tr>
                            <th scope="col">Order Image</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Order Date</th>
                            {order.some(
                              (C) => C.orderStatus !== "declined"
                            ) && <th scope="col">Action</th>}
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {Array.isArray(order) && order.length !== 0 ? (
                          order.map((w, i) => (
                            <tr key={i}>
                              <td className="product-thumbnail">
                                <Link
                                  to={`/ProductDetails/${w.productId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <span>
                                    <img
                                      src={`${process.env.REACT_APP_URL}${w.variantImages[0]}`}
                                      alt="item"
                                      style={{ objectFit: "cover" }}
                                    />
                                  </span>
                                </Link>
                              </td>
                              <td className="product-name">
                                <Link
                                  to={`/orderdetail/${w.orderId}`}
                                  style={{
                                    textTransform: "capitalize",
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  {w.orderId}
                                </Link>
                              </td>
                              <td className="product-price">
                                <Link
                                  to={`/orderdetail/${w.orderId}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  <span style={{ textTransform: "capitalize" }}>
                                    {w.orderStatus}
                                  </span>
                                </Link>
                              </td>
                              <td className="product-name">{w.timestamp}</td>
                              {w.orderStatus !== "declined" && (
                                <td className="product-subtotal">
                                  <Link
                                    to={`/orderdetail/${w.orderId}`}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    <button
                                      className="default-btn border-radius-5"
                                      style={{
                                        padding: "0px 10px 5px 10px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <IoEye />
                                    </button>
                                  </Link>
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>
                              <h3 className="mb-5 pb-3">No Order Found</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Order;
