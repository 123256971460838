import React, { useEffect, useState } from "react";
import { getAddress, DeleteAddress, globalLoderAction } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./getadress.css";

function GetAddress(props) {
  const [error, setError] = useState("");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(getAddress())
      .then(() => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch]);

  const getaddr = useSelector((state) => state.getAddress.payload);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  return (
    <section className="cart-wraps-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <form>
              <div className="cart-wraps">
                <div className="cart-table table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Address Type</th>
                        <th scope="col">Landmark</th>
                        <th scope="col">Street</th>
                        <th scope="col">City</th>
                        <th scope="col">District</th>
                        <th scope="col">State</th>
                        <th scope="col">Pincode</th>
                        <th scope="col" colspan="2">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(getaddr) && getaddr.length !== 0 ? (
                        getaddr.map((G, i) => (
                          <tr key={i}>
                            <td
                              data-label="Address Type"
                              className="product-thumbnail"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.addressType}
                            </td>
                            <td
                              data-label="Landmark"
                              className="product-name"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.landmark}
                            </td>
                            <td
                              data-label="Street"
                              className="product-price"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.street}
                            </td>
                            <td
                              data-label="City"
                              className="product-name"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.city}
                            </td>
                            <td
                              data-label="District"
                              className="product-name"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.district}
                            </td>
                            <td
                              data-label="State"
                              className="product-price"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.state}
                            </td>
                            <td
                              data-label="Pincode"
                              className="product-name"
                              style={{ textTransform: "capitalize" }}
                            >
                              {G.zipcode}
                            </td>
                            <td data-label="Action" className="product-name">
                              <Link
                                style={{
                                  cursor: "pointer",
                                  paddingLeft: "10px",
                                }}
                                to={"/UpdateAddress/" + G.id}
                              >
                                <i
                                  style={{
                                    fontSize: "23px",
                                    backgroundColor: "#0000001c",
                                    borderRadius: "0",
                                    padding: "10px",
                                  }}
                                  className="bx bx-edit-alt"
                                ></i>
                              </Link>
                            </td>
                            <td data-label="Action" className="product-price">
                              <a
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  dispatch(
                                    DeleteAddress({
                                      addressId: G.id,
                                      action: "delete",
                                    })
                                  ).catch((err) => {
                                    setError(err.response.data.error.message);
                                  });
                                }}
                              >
                                <i
                                  style={{
                                    fontSize: "23px",
                                    backgroundColor: "#a41919",
                                    borderRadius: "0",
                                    padding: "10px",
                                  }}
                                  className="bx bx-trash"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} style={{ textAlign: "center" }}>
                            <h3 className="mb-5 pb-3">No Address Found</h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetAddress;
