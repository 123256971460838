import React, { useState, useEffect } from "react";
import { globalLoderAction } from "../Redux/Action";
import { BsCaretRightFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import about from "../assets/image/about.jpeg";
import about1 from "../assets/image/about1.jpeg";
import about2 from "../assets/image/about2.jpeg";
import about3 from "../assets/image/about1.jpg";


function About() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    const timer = setTimeout(() => {
      dispatch(globalLoderAction("hideLoader"));
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div class="inner-banner inner-banner-bg10">
        <div class="container">
          <div class="inner-title">
            <h3 style={{ color: "#05af6a" }}>About us</h3>
            <ul>
              <li>
                <a style={{ color: "#f9c113" }} href="/">
                  Home
                </a>
              </li>
              <li style={{ color: "#fff" }}>About us</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>Our Story</span>
                  </div>

                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""} We are a full-on Organic Honey and Honey based product
                    supporter and producer.
                    <p></p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""} “<b>Aadhi Marudham</b>” is backed by value and purpose,
                    not just for ourselves but the people and our most trusted
                    customers.{" "}
                  </p>
                  <p>
                    {" "}
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""} The existence of Honey Bees, a species that is so
                    crucial in the chain of food production and Honey as a
                    product with its ability to purpose and revitalize lives is
                    incredible, we as not sellers but also humans do see and
                    want to spread this incredible goodness to fellow human
                    beings for the society and the mankind to flourish in as
                    many aspects of health as possible.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about2} alt="Cosmetic" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about-area-three pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-img3">
                <img src={about} alt="About" />
                <div class="about-sub-img">
                  <img src={about1} alt="About" />
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-content-two">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>About Us</span>
                  </div>
                  <h2>Why Aadhi Marudham?</h2>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-12">
                    <ul class="about-list">
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Range of Varieties</h3>
                      </li>
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Flexible Subscription Model</h3>
                      </li>{" "}
                      <li>
                        <i class="bx bx-check"></i>
                        <h3>Making Processes</h3>
                      </li>
                    </ul>
                  </div>
                
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>Bees are for life, Honey is our Boon!</span>
                  </div>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""} Our Research is backed by 2 Decades of learnings and
                    experiments.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""} Aadhi Marudham bloomed out as an Entity post in-depth
                    research on Bees, Honey, Farming, and the possible product
                    verse.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""}
                    What inspires us the most are the “need for it, the
                    challenges it throws, the benefits it gives”-we eventually
                    realized that “ Honey is not just an extravagant organic
                    sweetener but an essential ingredient for life and food
                    sustenance.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""}
                    Thanks to the Mentor behind Aadhi Marudham- “We owe you all
                    the goodness of 2 decades in our journey sir!” His ardent
                    interest to impart knowledge, train and educate the next
                    generations on Bee Keeping, its necessity and the Honey
                    industry as such is tremendous to be true.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} />
                    {""}
                    This has been our core factor of inspiration and motivation
                    to pursue the industry of Honey and Honey based products and
                    Bee Keeping.
                  </p>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about1} alt="Cosmetic" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cosmetic-area pt-100 pb-70">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="cosmetic-img">
                <img src={about3} alt="Cosmetic" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="cosmetic-content">
                <div class="section-title">
                  <div class="section-subtitle">
                    <img
                      src="assets/images/section-icon.png"
                      alt="Section Images"
                    />
                    <span>We are a firm Comb of Bees</span>
                  </div>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> {""}
                    United with a vision that resonates across our hearts and
                    minds- we envision to solve challenges prevailing in
                    bringing the natural sweetener "honey" to common people's
                    dining table.
                  </p>{" "}
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Our
                    initial search, though had begun with organic farming, we
                    landed at a point where we were hardly hit by the
                    understanding, of the role played by Honey Bees in
                    agriculture and the value Honey naturally provided.
                  </p>{" "}
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> {""}
                    And this led to deeper experiments and then varieties of
                    Honey from Aadhi Marudham.
                  </p>
                  <p>
                    <BsCaretRightFill style={{ color: "#6b9246" }} /> Closely
                    Knit by Intentions, Largely Diverse by Experiments!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="feature-area pt-100 pb-70">
        <div class="container">
          {/* <div class="section-title text-center mb-45">
                    <div class="section-subtitle">
                        <img src="assets/images/section-icon.png" alt="Section Images" />
                        <span>Feature</span>
                    </div>
                    <h2>Our Services Is Much Better</h2>
                </div> */}

          <div class="row justify-content-center">
            <div class="col-lg-12 col-12">
         
                <h3>
                  <i>
                    {" "}
                    “If the Bees Disappeared from the surface of the Globe the
                    man would only have four years of life left. No more Bees,
                    No more Pollination, No more Plants, No more Animals, No
                    more Man.”
                  </i>{" "}
                  - <b>Albert Einstein</b>
                </h3>
            
            </div>
          </div>
        </div>
      </div>

      {/* <div class="testimonials-area testimonials-bg2 ptb-100">
        <div class="container">
          <div class="testimonials-slider owl-carousel owl-theme">
            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Elizabeth Diana</h3>
              <span>SEO - Founder</span>
            </div>

            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Aruna Malis</h3>
              <span>Makeup Artists</span>
            </div>

            <div class="testimonials-item">
              <div class="section-title text-center">
                <div class="section-subtitle">
                  <img
                    src="assets/images/section-icon.png"
                    alt="Section Images"
                  />
                  <span>Testimonials</span>
                </div>
                <h2>Our Client Feedback</h2>
              </div>
              <i class="bx bxs-quote-right"></i>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humourrandomised words which don't look even slightly
                believable. If you are going to use a passage of LoremIpsum, you
                needbe sure therfirst true generator on the Internet. It uses a
                dictionary of over
              </p>
              <h3>Christopher Evan</h3>
              <span>Hairdresser</span>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div class="newsletter-area-widget section-bg ptb-100">
        <div class="container">
          <div class="newsletter-area">
            <div class="section-title text-center mb-45">
              <h2>Signup to Our Newsletter</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered
              </p>
            </div>
            <form class="newsletter-form" data-toggle="validator" method="POST">
              <input
                type="email"
                class="form-control"
                placeholder="Enter Your Email"
                name="EMAIL"
                required
                autocomplete="off"
              />
              <button class="subscribe-btn" type="submit">
                Subscribe
              </button>
              <div id="validator-newsletter" class="form-result"></div>
            </form>
          </div>
        </div>

        <div class="newsletter-shape">
          <div class="shape1">
            <img src="assets/images/shape/shape2.png" alt="Images" />
          </div>

          <div class="shape2">
            <img src="assets/images/shape/shape1.png" alt="Images" />
          </div>
        </div> 
      </div>*/}
    </>
  );
}
export default About;
