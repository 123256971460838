import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getordersdetails, globalLoderAction } from "../Redux/Action";
import TrackingOrder from "./TrackingOrder";

function Getorderdetails() {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(true);
  const { id } = useParams();
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    dispatch(globalLoderAction("showLoader"));
    dispatch(getordersdetails(getid))
      .then(() => setLoad(false))
      .catch(() => {
        console.log("Waiting For Network");
      })
      .finally(() => {
        dispatch(globalLoderAction("hideLoader"));
      });
  }, [dispatch, getid]);

  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);

  const getdetails = useSelector((state) => state.getordersdetails.payload);

  let isReviewedArray = getdetails?.orderInfo?.isReviewed;
  let isReviewed =
    Array.isArray(isReviewedArray) && isReviewedArray.includes(0);

  let deliverinfo = getdetails?.orderInfo?.orderStatus;

  return (
    <div>
      <div className="container-fluid">
        <div>
          <TrackingOrder />
        </div>
      </div>
      <section className="cart-wraps-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form>
                {Array.isArray(getdetails?.productInfo) &&
                  getdetails?.productInfo.length !== 0 && (
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      Product Review
                    </h3>
                  )}
                <div className="cart-wraps">
                  <div className="cart-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        {Array.isArray(getdetails?.productInfo) &&
                          getdetails?.productInfo.length !== 0 && (
                            <tr>
                              <th scope="col"> Product</th>
                              <th scope="col"> Name</th>
                              <th scope="col"> Unit</th>
                              <th scope="col"> Total</th>
                              {deliverinfo === "delivered" && (
                                <th scope="col">Review</th>
                              )}
                            </tr>
                          )}
                      </thead>
                      <tbody>
                        {Array.isArray(getdetails?.productInfo) &&
                        getdetails?.productInfo.length !== 0 ? (
                          getdetails.productInfo.map((w, i) => (
                            <tr key={i}>
                              <td className="product-thumbnail">
                                <img
                                  src={`${process.env.REACT_APP_URL}${w.variantImage}`}
                                  height={80}
                                  width={80}
                                  alt="item"
                                  style={{ objectFit: "cover" }}
                                />
                              </td>
                              <td
                                className="product-name"
                                style={{ textTransform: "capitalize" }}
                              >
                                {w.productName}
                              </td>
                              <td className="product-price">
                                <span className="unit-amount">{w.units}</span>
                              </td>
                              <td className="product-name">₹{w.totalPrice}</td>
                              {deliverinfo === "delivered" && (
                                <td className="product-price">
                                  <span className="unit-amount">
                                    {w.isReviewed === 1 ? (
                                      <span
                                        className="btn btn-dark"
                                        onClick={() => {
                                          if (show === i) setShow("");
                                          else setShow(i);
                                        }}
                                      >
                                        {show === i
                                          ? "Close Review"
                                          : "Write Review"}
                                      </span>
                                    ) : (
                                      <button
                                        className="btn btn-dark"
                                        disabled={true}
                                      >
                                        Reviewed
                                      </button>
                                    )}
                                  </span>
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} style={{ textAlign: "center" }}>
                              <h3 className="mb-5 pb-3">No Order Found</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Getorderdetails;
